.cardBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #232628;
  padding: 20px;
  border-radius: 8px;
}

.recentArticles {
  /* background-color: #00EEFF0F; */
  background-color: #232628;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.sidebarTitle {
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
}

.sidebarTitle::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color: #FFFFFF;
  margin: 8px auto 0;
}

.recentArticles ul {
  padding: 0;
  margin: 0;
}

.recentArticles li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #E0E0E0;
}

.recentArticles li:last-child {
  border-bottom: none;
}

.recentArticles a {
  text-decoration: none;
  font-family: "Inter", Sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  color: #FFFFFF;
  transition: color 0.3s ease;
  text-transform: capitalize;
}

.recentArticles a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}

.btnReadMore {
  background-color: #343a40;
  color: white;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
}

.btnReadMore:hover {
  background-color: #05f304;
  color: white;
  text-decoration: underline;
}

.headingTitle {
  min-height: 48px;
  max-height: 48px;
  line-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headingTitle a {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
  word-break: break-word;
  color: #000;
}

.headingTitle a:hover {
  text-decoration: underline;
}

.cardHeading {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
}

.verticalDivider::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #e0e0e0;
}

.verticalDivider {
  position: relative;
}

@media (max-width: 768px) {
  .verticalDivider {
    border-right: none;
  }
}

@media (max-width: 768px) {
  .card.article-card {
    max-height: none;
  }
}

@media (max-width: 768px) {
  .headingTitle a {
    font-size: 1.2rem;
  }

  .sidebarTitle {
    font-size: 1.3rem;
  }

  .recentArticles a {
    font-size: 1rem;
  }
}

.zoomImage {
  transition: transform 0.3s ease;
  transform: scale(1);
}

.zoomImage:hover {
  transform: scale(0.9);
}

._card img:hover {
  opacity: 0.6;
  transition: opacity 0.5s;
}

._card {
  background-color: #DCFBFF;
  max-width: 410px;
  margin: 0 auto;
}

.twoLineEllipsis {
  height: "48px";
  line-height: "1.2em";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.subscriptionBox {
  max-width: 500px;
  /* margin: 50px auto; */
  padding: 20px;
  text-align: center;
}

.subscriptionInput {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.subscribeBtn {
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.subscribeBtn:hover{
  color: #fff;
}

.termsText {
  font-size: 12px;
  color: #6c757d;
}

.termsText a {
  font-size: 12px;
  color: #6c757d;
}

.termsText a:hover {
  text-decoration: underline;
}

.errorMsg{
  color: #ff0404 !important;
  font-size: 14px;
  font-weight: 400;
  margin-right: 8rem;
}

.modalContent{
  background-color: #232628 !important;
  padding-top: 10px;
  padding-bottom: 40px;
}

.contentImgWidth{
  padding-bottom: 50px;
}

.contentImgWidth img {
  width: 100%;
}