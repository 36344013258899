

.modalContent{
  background-color: #232628 !important;
  padding-top: 10px;
  padding-bottom: 40px;
}

@media screen and (max-width: 991px) {
    .socialMediaLinksNav{
      flex-direction: row !important;
      justify-content: flex-end;
    }
    .socialMediaLinks{
      margin-right: 20px;
    }
    .pageNavigationNav{
        align-items: flex-end;
    }
    #responsive-navbar-nav{
        background-color: #191b1d;
    }
  }
  