.errorMsg{
  color: #ff0404 !important;
  font-size: 14px;
  font-weight: 400;
}

.image_preview {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.card_img{
  /* min-height: 100px; */
  width: 200px;
  height: 300px;
  object-fit: contain;
}
