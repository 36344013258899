.bannerRow {
  min-height: 100vh;
  height: auto;
  background-image: url(/src/assets/images/banner_bg.png);
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
}

.bannerHeading {
  font-size: 64px;
  text-transform: uppercase;
  text-align: center;
  line-height: 72px;
  font-weight: 800;
  color: white;
}

.beanderDescription {
  font-size: 16px;
  font-weight: 400;
}

.bannerContentRow {
  min-height: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

.productDeliveryRow {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pdItemCol {
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  padding: 40px 25px;
}

.pdItemRow {
  padding-top: 100px;
}

.portfolioRow{
  padding-top: 100px;
  padding-bottom: 200px;
}

.portfolioItem {
  width: 260px;
  height: 372px;
  display: flex !important;
  justify-content: center;
}

.portfolioItem:focus-visible{
  outline: 0 !important;
}

.serviceRow, .contactUsRow {
  background-color: #191b1d;
}

.serviceDiv, .contactUsDiv {
  background-image: url(/src/assets/images/service-bg.png);
  padding-top: 200px;
  padding-bottom: 150px;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.serviceItemDiv {
  background-color: #232628;
  padding: 35px 30px;
  min-height: 320px;
  position: relative;
}

.serviceItemDiv:hover {
  box-shadow: 0px 0px 13px #237274;
}

.serviceItemBtn {
  position: absolute;
  bottom: 30px;
}

.serviceItemArrow {
  margin-left: 5px;
  transition: 0.5s;
}

.serviceItemBtn:hover > .serviceItemArrow {
  margin-left: 10px;
  transition: 0.5s;
}

.teamRow{
  padding-top: 150px;
  padding-bottom: 150px;
}

.formItem{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background-color: #232628 !important;
  outline: 0 !important;
  border: none !important;
  color: white !important;
}

.formItem:hover{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background-color: #232628 !important;
  outline: 0 !important;
  border: none !important;
  color: white !important;
}

.formItem:focus{
  color: white !important;
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.photoInputList{
  background-color: #232628 !important;
}

.teamImg{
  object-fit: contain;
}

.modalContent{
  background-color: #232628 !important;
  padding-top: 10px;
  padding-bottom: 40px;
}

.errorMsg{
  color: #ff0404 !important;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 1199px) {

  .pdItemCol {
   padding-bottom: 0px;
  }
  .pdItemCol h2{
    font-size: 20px;
  }
  .teamImg{
    width: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 991px) {
  
  .socialMediaLinksNav{
    flex-direction: row !important;
    justify-content: flex-end;
  }
  .socialMediaLinks{
    margin-right: 20px;
  }
  .pageNavigationNav{
      align-items: flex-end;
  }
  .bannerHeading {
    font-size: 50px;
  }
  .serviceDiv, .contactUsDiv {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .teamRow{
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  .portfolioRow{
    padding-top: 0px;
    padding-bottom: 100px;
  }

}

@media screen and (max-width: 375px) {
  .bannerHeading {
    font-size: 40px;
    line-height: 52px;
  }
}
