.aboutUsBanner {
  height: 50vh;
  background-image: url(/src/assets/images/banner_bg.png);
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
}

.bannerHeading {
  font-size: 64px;
  text-transform: uppercase;
  text-align: center;
  line-height: 72px;
  font-weight: 800;
  color: white;
}

@media screen and (max-width: 991px) {
  .bannerHeading {
    font-size: 50px;
  }
}
