* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-24 {
  font-size: 24px;
}

.font-48 {
  font-size: 48px;
}

.font-64 {
  font-size: 64px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-16 {
  line-height: 16px;
}

.theme-color-red {
  color: #e92c2c;
}

.theme-background-red {
  background-color: #e92c2c;
}

.cursor-pointer {
  cursor: pointer;
}

.no-border {
  border: none !important;
}

.no-radius {
  border-radius: 0px !important;
}

.no-background {
  background-color: transparent !important;
}

.theme-border-color {
  border-color: #777777;
}

.react-tel-input .form-control {
  height: unset;
  width: 100%;
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:focus {
  background-color: #232628 !important;
  border-radius: unset;
}

.react-tel-input .selected-flag:hover {
  background-color: #232628 !important;
}

.react-tel-input .country-list .country:hover {
  background-color: #2c2c2c;
}

.react-tel-input .country-list .country.highlight {
  background-color: #141414;
}

#site-header{
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
}

.header-black-bg{
 background-color: #191b1d;
}

@media screen and (max-width: 991px) {
  #responsive-navbar-nav{
      background-color: #191b1d;
  }
}