.link {
    text-decoration: none;
    color: white !important;
}

.link:hover {
    text-decoration: none;
    color: white !important;
}

.content_container {
    max-height: 90vh;
}